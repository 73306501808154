/* You can add global styles to this file, and also import other style files */
.p-component {
    font-size: inherit;
  }

  .p-multiselect {
    height: 33px;
    font-size: small;
    width: 100%;
    border: 1px solid;
  }
  .pi {
    font-size: inherit;
  }

  .p-multiselect .p-multiselect-label {
    padding-top: 0.2rem;
    padding-left: 0.2rem;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-close {
    width: 1rem;
    height: 1rem;
  }

  .p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
    top: 1.5rem;
  }

  .p-multiselect-panel .p-multiselect-header {
    padding: 0rem 0.5rem;
  }

  table tbody {
    display: block;
    max-height: 60vh;
    overflow-y: scroll;
  }

  table thead, table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .card:hover, button:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .card {
    border-color: #004A70;
  }
  .card-header {
    background-color:#004A70;
    color:white;
    text-align: center;
  }
  select,
  input[type=text],
  input[type=number],
  input[type=date],
  input[type=file],
  input[type=password],
  input[type=time],
  input[type=month],
  input[type=email],
  input[type=datetime-local],
  textarea {
    width: 100%;
    height: 33px;
    padding: 4px 20px;
    box-sizing: border-box;
    border: 1px solid gray;
    margin: 8px 0;
  }

  select {
    height: 33px;
  }
  
  textarea {
    height: auto;
  }

  /* clr-icon svg {
    width: 16px;
    height: 16px;
  } */

  .p-autocomplete {
    width: 100%;
  }

  .p-autocomplete-dropdown {
    display: none;
  }

  .p-dropdown {
    width: 100%;
    height: 33px;
    border: 1px solid;
    margin: 8px 0;
  }

  .p-dropdown-label {
    font-size: inherit;
    margin-top: -0.3rem;
  }

  /* .p-dropdown-label div {
    margin-top: -0.3rem;
  } */

  .p-dropdown-clear-icon {
    margin-top: -.3rem;
  }

  .table .p-dropdown {
    margin: 0px;
    text-align: left;
  }

  .table-border th, .table-border td {
    border: 1px solid black;
  }